// scrollEffects.js
const NO_EVENT = 0;
const EVENT_OCCURRED = 1;
let sectionIndex = -1;

let animations = [
    {
        sectionID: 'PROJECT_END',
        triggerPos: 260,
        animationElems: {
            SpreadsheetPanel: {
                prev: { classesToAdd: [], classesToRemove: ['reveal'] },
                next: { classesToAdd: ['reveal'], classesToRemove: [] }
            }
        },
        scriptElems: {}
    },
    {
        sectionID: 'PROJECT_END',
        triggerPos: 220,
        animationElems: {
            SpreadsheetPanel: {
                prev: { classesToAdd: ['reveal'], classesToRemove: ['hide'] },
                next: { classesToAdd: ['hide'], classesToRemove: ['reveal'] }
            }
        },
        scriptElems: {}
    },
    {
        sectionID: 'PROJECT_END',
        triggerPos: 200,
        animationElems: {
            Intellisheets: {
                prev: { classesToAdd: [], classesToRemove: ['reveal'] },
                next: { classesToAdd: ['reveal'], classesToRemove: [] }
            }
        },
        scriptElems: {}
    },
    {
        sectionID: 'PROJECT_END',
        triggerPos: 160,
        animationElems: {
            Intellisheets: {
                prev: { classesToAdd: ['reveal'], classesToRemove: ['hide'] },
                next: { classesToAdd: ['hide'], classesToRemove: ['reveal'] }
            }
        },
        scriptElems: {}
    },
    {
        sectionID: 'PROJECT_END',
        triggerPos: 140,
        animationElems: {
            Datafolio: {
                prev: { classesToAdd: [], classesToRemove: ['reveal'] },
                next: { classesToAdd: ['reveal'], classesToRemove: [] }
            }
        },
        scriptElems: {}
    },
    {
        sectionID: 'PROJECT_END',
        triggerPos: 100,
        animationElems: {
            Datafolio: {
                prev: { classesToAdd: ['reveal'], classesToRemove: ['hide'] },
                next: { classesToAdd: ['hide'], classesToRemove: ['reveal'] }
            }
        },
        scriptElems: {}
    },
    {
        sectionID: 'PROJECT_END',
        triggerPos: 40,
        animationElems: {
            WanderlustCafe: {
                prev: { classesToAdd: [], classesToRemove: ['reveal'] },
                next: { classesToAdd: ['reveal'], classesToRemove: [] }
            }
        },
        scriptElems: {}
    },
];

let flooded = false;
function choreograph() {
    let endpoint = document.querySelector('#intro');
    let distanceFromWindowToPageTop = endpoint.getBoundingClientRect().top;
    console.log(window.innerHeight + ' ' + -distanceFromWindowToPageTop);
    if (-distanceFromWindowToPageTop * 2 < window.innerHeight) {
        document.querySelector('#waveBorder').style.bottom = -2 * distanceFromWindowToPageTop + 'px';
        flooded = false;
    }
    if (flooded == false && -distanceFromWindowToPageTop * 2 > window.innerHeight) {
        document.querySelector('#waveBorder').style.bottom = window.innerHeight + 'px';
        flooded = true;
    }
    console.log(sectionIndex);
    if (applyPrevTriggers() == NO_EVENT) {
        applyNextTriggers();
    }
}

function applyPrevTriggers() {
    if (sectionIndex > -1) {
        let currentPos = getDistanceOfWindowToEndpoint(sectionIndex);
        let triggerPos = getDistanceOfTriggerToEndpoint(sectionIndex);
        if (triggerPos < currentPos) {
            applyAnimations(sectionIndex, 'prev');
            applyScripts(sectionIndex, 'prev');
            applyPrevTriggers(sectionIndex--);
            return EVENT_OCCURRED;
        }
    }
    return NO_EVENT;
}

function applyNextTriggers() {
    if (sectionIndex < animations.length - 1 && animations.length > 0) {
        let currentPos = getDistanceOfWindowToEndpoint(sectionIndex + 1);
        let triggerPos = getDistanceOfTriggerToEndpoint(sectionIndex + 1);
        if (triggerPos > currentPos) {
            applyAnimations(sectionIndex + 1, 'next');
            applyScripts(sectionIndex + 1, 'next');
            applyNextTriggers(sectionIndex++);
            return EVENT_OCCURRED;
        }
    }
    return NO_EVENT;
}

function getDistanceOfWindowToEndpoint(sectionIndex) {
    let endpoint = document.querySelector(`#${animations[sectionIndex].sectionID}`);
    return endpoint.getBoundingClientRect().top;
}

function getDistanceOfTriggerToEndpoint(sectionIndex) {
    let windowHeight = window.innerHeight;
    return animations[sectionIndex].triggerPos * windowHeight / 100;
}

function applyAnimations(sectionIndex, direction) {
    for (let [elemID, classApplications] of Object.entries(animations[sectionIndex].animationElems)) {
        let elem = document.querySelector(`#${elemID}`);
        addClassesToElem(elem, classApplications[direction].classesToAdd);
        removeClassesFromElem(elem, classApplications[direction].classesToRemove);
    }
}

function addClassesToElem(elem, classesToAdd) {
    for (let name of classesToAdd) {
        elem.classList.add(name);
    }
}

function removeClassesFromElem(elem, classesToRemove) {
    for (let name of classesToRemove) {
        elem.classList.remove(name);
    }
}

function applyScripts(sectionIndex, direction) {
    for (let [elemID, functionApplications] of Object.entries(animations[sectionIndex].scriptElems)) {
        callFunctions(elemID, animations[sectionIndex].sectionID, functionApplications[direction].functions);
    }
}

function callFunctions(elemID, endpointID, functions) {
    for (let i = 0; i < functions.length; ++i) {
        console.log('applying script');
        functions[i](elemID, endpointID);
    }
}

export default choreograph;