import { useEffect } from 'react';
import './css/App.css';
import './css/waves.css';

function App() {

  useEffect(() => {
  });
  return (
    <div id='contentWrapper'>
      <div id="content">
        <div id="intro">
          <div id="whoAmI">
            <p id="name">Rakesh Bandi</p>
            <p id="webDeveloper">Web Developer</p>
          </div>
          <div id="skills">
            <p className="bold">Frontend</p>
            <p className="skillDetails">HTML/CSS/JS | ReactJS | Redux</p>
            <p className="bold">Backend</p>
            <p className="skillDetails">Node | MongoDB | SendGrid | Stripe </p>
            <p className="bold">Platform</p>
            <p className="skillDetails">Github | Firebase | Heroku</p>
          </div>
        </div>
        <div id="projects">
          <div id='waveBorder'>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
                <path id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
            <div id='afterWave'><p style={{ textAlign: 'center', fontSize: 'calc(10px + 1.4vmin)' }}>Release dates<br />11/30: Finish CSS polish, implement &#40;format bar + testing&#41; and documentation for SpreadsheetPanel</p></div>
          </div>
          <div id="SpreadsheetPanel" className='SpreadsheetPanel hidden'>
            <div id='SpreadsheetPanel__contents'>
              <p id="SpreadsheetPanelName">SpreadsheetPanel()</p>
              <div className="detailRow">
                <p className="rowNum first">1</p>
                <p className="rowData first">Developed spreadsheet editor component in ReactJS + Redux (RTK) from
                  scratch. Supports text
                  entry, row/col resizing, and undo/redo.</p>
              </div>
              <div className="detailRow">
                <p className="rowNum">2</p>
                <p className="rowData">100% coverage via atomic, unit, and end-to-end tests in vanilla. Applied in
                  Selenium CI workflow.
                </p>
              </div>
              <div className="detailRow">
                <p className="rowNum last">3</p>
                <p className="rowData">20 page technical design doc</p>
              </div>
            </div>
          </div>
          <div id="Intellisheets" className='Intellisheets hidden'>
            <div id='Intellisheets__contents'>
              <div id="IntellisheetsName">
                <img id='tulip' src="tulip.png" />
                <p style={{ margin: 0 }}>Intellisheets</p>
              </div>
              <p className="bulletRow">• Developed pared-down Google Sheets in ReactJS, to showcase my SpreadsheetPanel()
              </p>
              <p className="bulletRow">• Handwritten user auth (bcrypt + JWT cookies), with SendGrid for MFA, password
                resets, etc.</p>
              <button onClick={linkToLecture}>Video lecture &#40; slightly outdated &#41;</button>
              <button onClick={linkToIntellisheets}>Live Demo</button>
            </div>
          </div>
          <div id="Datafolio" className='Datafolio hidden'>
            <div id="Datafolio__contents">
              <p id="DatafolioName">Datafolio</p>
              <p className="bulletRow">• Implemented mock landing page in ReactJS, for a no-code dashboard builder.
              </p>
              <p className="bulletRow">• Designed orchestration logic to easily insert scroll animations. Responsive, with
                Lighthouse score of 80.</p>
              <button onClick={linkToDataFolio}>Live Demo</button>
            </div>
          </div>
          <div id="WanderlustCafe" className='WanderlustCafe hidden'>
            <div id='WanderlustCafe__contents'>
              <p id="WanderlustCafeName">Wanderlust Cafe</p>
              <p className="bulletRow">• Developed e-commerce website in ReactJS, for a small business selling food at
                farmer’s markets.
              </p>
              <p className="bulletRow">• Integrated with Stripe for payment processing, and SendGrid for pick up reminders
              </p>
              <button onClick={linkToWanderlustCafe}>Live Demo</button>
            </div>
          </div>
          <div id='PROJECT_END'></div>
        </div>
        <div id="contact">
          <div id='waveBorder2'>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 14 150 60" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
                <path id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="#fff" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgb(173, 216, 230,0.3)" />
                <use xlinkHref="#gentle-wave" x="48" y="7" fill="rgba(173, 216, 230, 0.5)" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(173, 216, 230, 0.7)" />
              </g>
            </svg>
          </div>
          <div id='contactPanel'>
            <div className="contactEntry">
              <p className="contactHeader">Cell: </p>
              <p>831-346-2946</p>
            </div>
            <div className="contactEntry">
              <p className="contactHeader">Email: </p>
              <p>rbandi211570@gmail.com</p>
            </div>
            <div className="contactEntry">
              <p className="contactHeader">LinkedIn: </p>
              <a href='https://linkedin.com/in/rakesh-bandi-34415519a'>rakesh-bandi-34415519a</a>
            </div>
            <div className="contactEntry">
              <p className="contactHeader">Github: </p>
              <a href='https://github.com/rrb211570'>rrb211570</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function linkToLecture() {
  window.location.href = 'https://drive.google.com/file/d/1FRhudjomB7m_oT2v5oVdF5cp4KwTXdmC/view?usp=sharing';
}

function linkToIntellisheets() {
  window.location.href = 'https://intellisheets.me';
}

function linkToDataFolio() {
  window.location.href = 'https://datafolio.net';
}

function linkToWanderlustCafe() {
  window.location.href = 'https://wanderlust-cafe.com';
}

export default App;
